import axios from "axios";
import { serverUrl } from "../../../../ApiRoute";
import moment from "moment";
import store from "../../../redux";
import { reportType } from "./data";
import { v4 as uuidv4 } from "uuid"; // For generating unique IDs
import { addUnitReport, updateCallCount } from "../../../redux/action/report";
import { messages } from "../../../common/notification";
import { averagePower, calculateUnits, spendingFunctionKvaH } from "../../../common/function";

function msToTime(duration) {
  let milliseconds = parseInt((duration % 1000) / 100),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + "H : " + minutes + "M";
}

function findDatesInRange(startDate, endDate) {
  let dateArray = [];
  let currentDate = new Date(startDate);

  // Ensure the endDate is inclusive
  endDate = new Date(endDate);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return dateArray;
}

export const fetchDailyUnitsSnapshot = async (id, date,kvah=false) => {
  try {
    let res = await axios.post(`${serverUrl.aws}/units/day`, {
      deviceId: id.trim(),
      day: date,
    });
    if (res.data.success) {
      let unit = 0;
      let unitsVA=0
      let offlineUnit = 0;
      let averageOfflineUnit = 0
      let unitsData = res.data.data.snapshot.units;
      let power = 0
      let count=0
      for (let id in unitsData) {
          if(unitsData[id].avgPf){
            power+=unitsData[id].avgPf
            count+=1
          }
      }
     let avgP=power/count
     
      for (let id in unitsData) {
        
        unit += unitsData[id].unitsConsumed;
        offlineUnit+=unitsData[id].offlineUnits
       
        if(kvah===true && unitsData[id].unitsVA){
          if(avgP!==0){
            averageOfflineUnit+=unitsData[id].offlineUnits/avgP
          }
          unitsVA+=unitsData[id].unitsVA
        }
        if (unitsData[id].hasOwnProperty("syncedData")) {
          let val = Object.values(unitsData[id].syncedData);
          for (let v of val) {
            if (v > 10) {
              continue;
            }
            unit += v;
          }
        }
      }
      let data={
        totalOnlineOffline:unit+offlineUnit,
        totalUnitVAH:unitsVA+averageOfflineUnit,
        avgPower:avgP

      }
      return !kvah?unit:data;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

export const calculateUnitReport = async (range, devices, selectedType,houseSelected) => {
  
  let dates = findDatesInRange(range.startDate, range.endDate);
  let totalData = [];
  const reportId = uuidv4();
  const reportIdAll = uuidv4();
  // Generate a unique ID for the report
  if (selectedType.name === reportType[0].name) {
    store.dispatch(
      addUnitReport(reportId, {
        date: range,
        type: `${reportType[0].name}`,
        data: [],
        totalCalls:dates.length*devices.length,currentCall:0 
      })
    );
  } else if (selectedType.name === reportType[1].name) {
    store.dispatch(
      addUnitReport(reportIdAll, {
        date: range,
        type: `${reportType[1].name}`,
        data: [],
        totalCalls:dates.length*devices.length,currentCall:0 
      })
    );
  }
  try {
    for(let house of houseSelected){
     
      for (let date of dates) {
        let obj = {
          Date: moment(date).format("DD-MM-YYYY"),
        };
        for (let device of devices) {
       if(device.houseName!==house.label) continue;
          let name = `${
            device.name !== undefined && device.name !== ""
              ? `${device.name}`
              : device.deviceId
          }`;
          obj['Site']=device.houseName
          let unit = await fetchDailyUnitsSnapshot(device.deviceId, date);
          if(selectedType.name === reportType[0].name){
          store.dispatch(updateCallCount(reportId))
          }else{
            store.dispatch(updateCallCount(reportIdAll))
          }
          if (obj[name] === undefined) {
            obj[name] = Number(unit.toFixed(2));
          }
        }
        totalData.push(obj);
      }
    }
   

    if (selectedType.name === reportType[0].name) {
      store.dispatch(
        addUnitReport(reportId, {
          date: range,
          type: `${reportType[0].name}`,
          data: totalData,
          success: true,
        })
      );
    }
  } catch (error) {
    if (selectedType.name === reportType[0].name) {
      store.dispatch(
        addUnitReport(reportId, {
          date: range,
          type: `${reportType[0].name}`,
          data: [],
          success: false,
        })
      );
    }
  }

  if (selectedType.name === reportType[1].name) {
    try {
      let combineUnitData = []
      for(let house of houseSelected){
      let totalDataDataObj = {
        Date: `${moment(range.startDate).format("DD-MM-YYYY")} - ${moment(
          range.endDate
        ).format("DD-MM-YYYY")}`,
      };
      for (let da of totalData.filter(data=>data.Site===house.label)) {
        totalDataDataObj['Site']= da.Site;
        let ke = Object.keys(da);
        for (let id of ke) {
          if (!["Date", "Site"].includes(id)) {
            if (totalDataDataObj[id] === undefined) {
              totalDataDataObj[id] = 0;
            }
          

            totalDataDataObj[id] =
              Number((Number(totalDataDataObj[id].toFixed(2)) +
              Number(da[id].toFixed(2))).toFixed(2));
          }
        }
  
      }
      combineUnitData.push(totalDataDataObj)
    }
      store.dispatch(
        addUnitReport(reportIdAll, {
          date: range,
          type: `${reportType[1].name}`,
          data: combineUnitData,
          success: true,
        })
      );
    } catch (error) {
      store.dispatch(
        addUnitReport(reportIdAll, {
          date: range,
          type: `${reportType[1].name}`,
          data: [],
          success: false,
        })
      );
    }
  }
};
export const calculateUnitKvaHReport = async (range, devices, selectedType,houseSelected) => {
  let name="Main Meter"
  devices=devices.filter((e)=>e.name.includes(name))
  let dates = findDatesInRange(range.startDate, range.endDate);
  let totalData = [];
  const reportId = uuidv4();
  const reportIdAll = uuidv4();
  // Generate a unique ID for the report
 
  if (selectedType.name === reportType[5].name) {
    store.dispatch(
      addUnitReport(reportId, {
        date: range,
        type: `${reportType[5].name}`,
        data: [],
        totalCalls:dates.length*devices.length,currentCall:0 
      })
    );
  } else if (selectedType.name === reportType[1].name) {
    store.dispatch(
      addUnitReport(reportIdAll, {
        date: range,
        type: `${reportType[1].name}`,
        data: [],
        totalCalls:dates.length*devices.length,currentCall:0 
      })
    );
  }

  try {
    for(let house of houseSelected){
      for (let date of dates) {
      
        for (let device of devices) {
       if(device.houseName!==house.label) continue;
  
          let unit = await fetchDailyUnitsSnapshot(device.deviceId, date,true);
          let obj = {
            Date: moment(date).format("DD-MM-YYYY"),
            Site:device.houseName,
            DeviceID:device.deviceId,
            "Unit Kwh":unit.totalOnlineOffline.toFixed(2) || "",
            "Unit Vah":unit.totalUnitVAH.toFixed(2) || "",
            "Average Power Factor":unit.avgPower.toFixed(2) || ""
          };
          
          totalData.push(obj);
          if(selectedType.name === reportType[5].name){
          store.dispatch(updateCallCount(reportId))
          }else{
            store.dispatch(updateCallCount(reportIdAll))
          }
          
        }
       
      }
    }
   

    if (selectedType.name === reportType[5].name) {
      store.dispatch(
        addUnitReport(reportId, {
          date: range,
          type: `${reportType[5].name}`,
          data: totalData,
          success: true,
        })
      );
    }
  } catch (error) {
    if (selectedType.name === reportType[5].name) {
      store.dispatch(
        addUnitReport(reportId, {
          date: range,
          type: `${reportType[0].name}`,
          data: [],
          success: false,
        })
      );
    }
  }

  if (selectedType.name === reportType[1].name) {
    try {
      let combineUnitData = []
      for(let house of houseSelected){
      let totalDataDataObj = {
        Date: `${moment(range.startDate).format("DD-MM-YYYY")} - ${moment(
          range.endDate
        ).format("DD-MM-YYYY")}`,
      };
      for (let da of totalData.filter(data=>data.Site===house.label)) {
        totalDataDataObj['Site']= da.Site;
        let ke = Object.keys(da);
        for (let id of ke) {
          if (!["Date", "Site"].includes(id)) {
            if (totalDataDataObj[id] === undefined) {
              totalDataDataObj[id] = 0;
            }
          

            totalDataDataObj[id] =
              Number((Number(totalDataDataObj[id].toFixed(2)) +
              Number(da[id].toFixed(2))).toFixed(2));
          }
        }
  
      }
      combineUnitData.push(totalDataDataObj)
    }
      store.dispatch(
        addUnitReport(reportIdAll, {
          date: range,
          type: `${reportType[1].name}`,
          data: combineUnitData,
          success: true,
        })
      );
    } catch (error) {
      store.dispatch(
        addUnitReport(reportIdAll, {
          date: range,
          type: `${reportType[1].name}`,
          data: [],
          success: false,
        })
      );
    }
  }
};

// log api

export const fetchAndStoreSingleDeviceSelectedDayData = async (
  deviceId,
  date
) => {
  try {
    const res = await axios.post(`${serverUrl.aws}/analysis/day`, {
      deviceId: deviceId,
      day: date,
    });
 
    if (res.data.success) {
      return { data: res.data.data.snapshot };
    } else {
      return { data: {} };
    }
  } catch (err) {
    console.error(err,deviceId,date,"eeeeee");
    if(err.status==400 && err.response.data.data.error=="ProvisionedThroughputExceededException: The level of configured provisioned throughput for the table was exceeded. Consider increasing your provisioning level with the UpdateTable API."){
      // Return a promise to handle the delay
      return new Promise((resolve) => {
        setTimeout(async () => {
          const result = await fetchAndStoreSingleDeviceSelectedDayData(deviceId, date);
          resolve(result); // Resolve the promise with the retry result
        }, 5000);
      });
    }else{
    return { data: {} };
    }
  }
};

export const fetchSingleDeviceTodayData = async (deviceId) => {
  try {
    const res = await axios.post(`${serverUrl.aws}/analysis/today`, {
      deviceId: deviceId,
    });

    if (res.data.success) {
      return { data: res.data.data.snapshot };
    } else {
      return { data: {} };
    }
  } catch (err) {
    console.error(err);
    if(err.status==400 && err.response.data.data.error=="ProvisionedThroughputExceededException: The level of configured provisioned throughput for the table was exceeded. Consider increasing your provisioning level with the UpdateTable API."){
      // Return a promise to handle the delay
      return new Promise((resolve) => {
        setTimeout(async () => {
          const result = await fetchSingleDeviceTodayData(deviceId);
          resolve(result); // Resolve the promise with the retry result
        }, 5000);
      });
    }else{
    return { data: {} };
    }
  }
};


export const temperatureReport = async (range, devices,houseSelected) => {
  let dates = findDatesInRange(range.startDate, range.endDate);
  const today = moment().startOf("day");
  let totalData = [];
  // Generate a unique ID for the report
  const reportId = uuidv4();
  store.dispatch(
    addUnitReport(reportId, { date: range, type: reportType[2].name, data: [],totalCalls:dates.length*devices.length,currentCall:0  })
  );
  try {
    for (let date of dates) {
      for (let device of devices) {
        let res = {};
        let name = `${
          device.name !== undefined && device.name !== ""
            ? `${device.name}-${device.deviceId}`
            : device.deviceId
        }`;
        if (moment(date).startOf("day").isSame(today, "day")) {
          res = await fetchSingleDeviceTodayData(device.deviceId);
          store.dispatch(updateCallCount(reportId))

        } else {
          res = await fetchAndStoreSingleDeviceSelectedDayData(
            device.deviceId,
            date
          );
          store.dispatch(updateCallCount(reportId))

        }

        if (Object.keys(res).length > 0) {
          if (Object.keys(res.data).length > 0) {
            if (res.data.hasOwnProperty("temperatureLogs")) {
              let filterTemp = res.data.temperatureLogs.filter((e) => e.t > 0);
           
              for (let temp of filterTemp) {
                let obj = {
                  Date: moment(date).format("DD-MM-YYYY"),
                  Time: moment(temp.ts).format("LTS"),
                  Site: device.houseName,
                  "Machine name": name,
                  Temperature: temp.t.toFixed(2),
                };
                totalData.push(obj);
              }
            }
          }
        }
      }
    }
    store.dispatch(
      addUnitReport(reportId, {
        date: range,
        type: reportType[2].name,
        data: totalData,
        success: true,
      })
    );
  } catch (error) {
    store.dispatch(
      addUnitReport(reportId, {
        date: range,
        type: reportType[2].name,
        data: [],
        success: false,
      })
    );
  }
};

export const loadLogsReport = async (range, devices,houseSelected) => {
  let dates = findDatesInRange(range.startDate, range.endDate);

  const today = moment().startOf("day");
  let totalData = [];
  // Generate a unique ID for the report
  const reportId = uuidv4();
  store.dispatch(
    addUnitReport(reportId, { date: range, type: reportType[3].name, data: [],totalCalls:dates.length*devices.length,currentCall:0 })
  );
  try {
    for (let date of dates) {
      let obj = {};
      for (let device of devices) {
        let res = {};
        let name = `${
          device.name !== undefined && device.name !== ""
            ? `${device.name}-${device.deviceId}`
            : device.deviceId
        }`;
        if (moment(date).startOf("day").isSame(today, "day")) {
          res = await fetchSingleDeviceTodayData(device.deviceId);
          store.dispatch(updateCallCount(reportId))

        } else {
          res = await fetchAndStoreSingleDeviceSelectedDayData(
            device.deviceId,
            date
          );
          store.dispatch(updateCallCount(reportId))

        }
        if (Object.keys(res).length > 0) {
          if (Object.keys(res.data).length > 0) {
            if (res.data.hasOwnProperty("loadLogs")) {
              let sortLogs = res.data.loadLogs.sort(
                (a, b) => parseFloat(a.ts) - parseFloat(b.ts)
              );
              let sortAccordingM = {
                0: [],
              };
              for (let p of sortLogs) {
                if (p.m === undefined || p.m === 0) {
                  sortAccordingM["0"].push(p);
                } else {
                  if (sortAccordingM[`${p.m}`] === undefined) {
                    sortAccordingM[`${p.m}`] = [];
                  }
                  sortAccordingM[`${p.m}`].push(p);
                }
              }
              for (let phase in sortAccordingM) {
                let logg = sortAccordingM[phase];
                for (let i = 0; i < sortAccordingM[phase].length; i++) {
                  let unit =
                    i < logg.length - 1
                      ? calculateUnits(logg[i + 1].ts - logg[i].ts, logg[i].p)
                      : { unitConsumed: 0 };
                  obj = {
                    Date: moment(date).format("DD-MM-YYYY"),
                    Time: moment(logg[i].ts).format("LTS"),
                    Site: device.houseName,
                    "Machine name": `${name}`,
                    Phase: Number(phase) + 1,
                    Voltage: logg[i]?.v.toFixed(2),
                    PF: logg[i]?.pf.toFixed(2),
                    Current: logg[i]?.c.toFixed(2),
                    Power: logg[i]?.p.toFixed(2),
                    "Units Consumed":
                      i === 0 ? 0 : unit.unitConsumed.toFixed(2),
                  };
                  totalData.push(obj);
                }
              }
            }
          }
        }
      }
    }

    store.dispatch(
      addUnitReport(reportId, {
        date: range,
        type: reportType[3].name,
        data: totalData,
        success: true,
      })
    );
  } catch (error) {
    store.dispatch(
      addUnitReport(reportId, {
        date: range,
        type: reportType[3].name,
        data: totalData,
        success: false,
      })
    );
  }
};

export const savingReport = async (range, devices,houseSelected) => {
  let dates = findDatesInRange(range.startDate, range.endDate);
  const today = moment().startOf("day");
  let totalData = [];
  // Generate a unique ID for the report
  const reportId = uuidv4();
  store.dispatch(
    addUnitReport(reportId, { date: range, type: reportType[4].name, data: [],totalCalls:dates.length*devices.length,currentCall:0 })
  );
  try {
    for (let date of dates) {
      let obj = {};
      for (let device of devices) {
        let res = {};
        let name = `${
          device.name !== undefined && device.name !== ""
            ? `${device.name}-${device.deviceId}`
            : device.deviceId
        }`;
        if (moment(date).startOf("day").isSame(today, "day")) {
          res = await fetchSingleDeviceTodayData(device.deviceId);
          store.dispatch(updateCallCount(reportId))

        } else {
          res = await fetchAndStoreSingleDeviceSelectedDayData(
            device.deviceId,
            date
          );
          store.dispatch(updateCallCount(reportId))
        }
        if (Object.keys(res).length > 0) {
          if (Object.keys(res.data).length > 0) {
            if (res.data.hasOwnProperty("loadLogs")) {
              let sortLogs = res.data.loadLogs.sort(
                (a, b) => parseFloat(a.ts) - parseFloat(b.ts)
              );
              let sortAccordingM = {
                0: [],
              };
              for (let p of sortLogs) {
                if (p.m === undefined || p.m === 0) {
                  sortAccordingM["0"].push(p);
                } else {
                  if (sortAccordingM[`${p.m}`] === undefined) {
                    sortAccordingM[`${p.m}`] = [];
                  }
                  sortAccordingM[`${p.m}`].push(p);
                }
              }
              for (let phase in sortAccordingM) {
                let logg = sortAccordingM[phase];
                let totalUnitConsume = 0;
                let totalUnitSaved = 0;
                let totalAverageTemp = "";
                const averageP = isNaN(averagePower(logg))
                  ? 0
                  : averagePower(logg);
                for (let i = 0; i < sortAccordingM[phase].length; i++) {
                  let unit =
                    i < logg.length - 1
                      ? calculateUnits(logg[i + 1].ts - logg[i].ts, logg[i].p)
                      : { unitConsumed: 0 };
                  totalUnitConsume =
                    Number(totalUnitConsume) +
                    Number(i === 0 ? 0 : unit.unitConsumed.toFixed(2));
                }
                if (Number(phase) === 0) {
                  if (
                    res.data.hasOwnProperty("smartOffSessions") &&
                    res.data.smartOffSessions.length >= 0
                  ) {
                    for (let smart of res.data.smartOffSessions) {
                      let usave = calculateUnits(
                        smart.end - smart.start,
                        averageP
                      );
                      totalUnitSaved =
                        Number(totalUnitSaved) +
                        Number(usave.unitConsumed.toFixed(2));
                    }
                  }

                  // Temperature
                  if (res.data.hasOwnProperty("temperatureLogs")) {
                    let filterTemp = res.data.temperatureLogs.filter(
                      (e) => e.t > 0
                    );
                    let totalTem = filterTemp.reduce((p, c) => {
                      return (p = Number(p) + Number(c.t));
                    }, 0);
                
                    let averageTemp =
                      filterTemp.length === 0
                        ? "0.00"
                        : (totalTem / filterTemp.length).toFixed(2);
                    // if (Number(averageTemp) <= 0 || averageTemp === "0.00") {
                    //   continue;
                    // }
                   
                    totalAverageTemp = averageTemp;
                  }
                }
                obj = {
                  Date: moment(date).format("DD-MM-YYYY"),
                  Site: device.houseName,
                  "Machine name": `${name}`,
                  Phase: Number(phase) + 1,
                  "Units Consumed": totalUnitConsume.toFixed(2),
                  "Units Saved": totalUnitSaved.toFixed(2),
                  "Temperature": totalAverageTemp,
                //   "On Time":
                //     res?.data?.meterOnTimes !== undefined &&
                //     Object.keys(res?.data?.meterOnTimes).length > 0
                //       ? res?.data?.meterOnTimes[phase] !== undefined
                //         ? msToTime(res?.data?.meterOnTimes[phase])
                //         : ""
                //       : "",
                };
                totalData.push(obj);
              }
            }
          }
        }
      }
    }

    store.dispatch(
      addUnitReport(reportId, {
        date: range,
        type: reportType[4].name,
        data: totalData,
        success: true,
      })
    );
  } catch (error) {
    console.log(error);
  }
};

// export const kvahReport = async (range, devices,houseSelected) => {
//   let dates = findDatesInRange(range.startDate, range.endDate);
//   const today = moment().startOf("day");
//   let totalData = [];
//   // Generate a unique ID for the report
//   const reportId = uuidv4();
//   store.dispatch(
//     addUnitReport(reportId, { date: range, type: reportType[5].name, data: [],totalCalls:dates.length*devices.length,currentCall:0  })
//   );
//   try {
//     for (let date of dates) {
//       let obj = {
//         Date: moment(date).format("DD-MM-YYYY"),
//       };
//       for (let device of devices) {
//         let res = {};

//         let name = `${
//           device.name !== undefined && device.name !== ""
//             ? `${device.name}`
//             : device.deviceId
//         }`;
//         obj['Site']=device.houseName
//         if (moment(date).startOf("day").isSame(today, "day")) {
//           res = await fetchSingleDeviceTodayData(device.deviceId);
//           store.dispatch(updateCallCount(reportId))

//         } else {
//           res = await fetchAndStoreSingleDeviceSelectedDayData(
//             device.deviceId,
//             date
//           );
//           store.dispatch(updateCallCount(reportId))

//         }

//         if (Object.keys(res).length > 0) {
//           if (Object.keys(res.data).length > 0) {
//             if (res.data.hasOwnProperty("loadLogs")) {
//               let sortedLogs = res.data.loadLogs.sort((a,b)=>a.ts-b.ts);
//               let sortAccordingM = {
//                 0: [],
//               };
//               for (let p of sortedLoadLogs) {
//                 if (p.m === undefined || p.m === 0) {
//                   sortAccordingM["0"].push(p);
//                 } else {
//                   if (sortAccordingM[`${p.m}`] === undefined) {
//                     sortAccordingM[`${p.m}`] = [];
//                   }
//                   sortAccordingM[`${p.m}`].push(p);
//                 }
//               }
//               for(let phase in sortAccordingM){
//                 let logs = await spendingFunctionKvaH(sortAccordingM[phase],1,1,false)
//                 if (obj[`${name}-${phase+1}`] === undefined) {
//                   obj[name] = Number(logs.unitConsumed.toFixed(2));
//                 }
//               }
           
             
//             }
//           }
//         }
//       }
//       totalData.push(obj)
//     }
//     store.dispatch(
//       addUnitReport(reportId, {
//         date: range,
//         type: reportType[5].name,
//         data: totalData,
//         success: true,
//       })
//     );
//   } catch (error) {
//     store.dispatch(
//       addUnitReport(reportId, {
//         date: range,
//         type: reportType[2].name,
//         data: [],
//         success: false,
//       })
//     );
//   }
// };