// export const carbonFromUnits = units => {
//   const carbons = units*0.5;
//   return cab
// }

import store from "../redux";

// export const putPowerInSmartOffSession = (smartOffSessions,loadLogs)=>{
//       let newSmartSessionst =  []
//        for(let session of smartOffSessions){
//           let time = session.start
//           for(let i=0;i<loadLogs.length;i++){
//             if(loadLogs[i].ts>time){
//               if(loadLogs[i-1].p!==0){
//               let obj = {...session,p:loadLogs[i-1]}
//               newSmartSessionst.push(obj)
//               continue
//               }else{
//                 for(let j=i-1;j<0;j--){
//                   if(loadLogs[j].p!==0){

//                   }
//                 }
//               }

//             }
//           }
//        }
// }

//---------------------------------------------------------------------------------------
export const temperatureData = (temperatureLog) => {
  let data = []
  for (let i of temperatureLog) {
    if (i.t > 0) {
      let d = [i.ts, i.t];
      data.push(d);
    }
  }
  return data
}

//---------------------------------------------------------------------------------------
export const currentData = (currentLog) => {
  let data = []
  for (let i of currentLog) {
    if (i.c > 0) {
      let d = [i.ts, i.c];
      data.push(d);
    }
  }
  return data
}

//---------------------------------------------------------------------------------------
export const voltageData = (voltageLog) => {
  let data = []
  for (let i of voltageLog) {
    if (i.c > 0) {
      let d = [i.ts, i.v];
      data.push(d);
    }
  }
  return data
}

//---------------------------------------------------------------------------------------
export const powerConsumption = (loadloag) => {
  let power = []
  for (let i of loadloag) {
    let data = [i.ts, i.p];
    power.push(data);
  }
  return power
}

//-------------------------------------------------------------------
// Define calculation functions
export const calculateGlobalData = (data, housePPU, deviceDetail) => {

  console.log(data, 'data')

  let deviceId = deviceDetail.deviceId
  let globalMoneySpent = 0;
  let globalMoneySaved = 0;
  let globalUnitConsumed = 0;
  let globalUnitSaved = 0;
  let globalUnitSavingPercentge = 0;

  let globalCarbonConsumedData = []
  let globalCarbonSavedData = []
  let globalMoneySpentData = []
  let globalMoneySavedData = []
  let globalUnitsConsumedData = []
  let globalUnitsSavedData = []
  let globalOnlineData = []
  let phaseLoadLogs = {};

  data.forEach(data => {
    if (Object.keys(data).length === 0) return;

    const { loadLogs, smartOffSessions } = data;
    const sortedLoadLogs =loadLogs!==undefined ?loadLogs.sort((a, b) => parseFloat(a.ts) - parseFloat(b.ts)):[];

    if (sortedLoadLogs.length === 0) {
      return;
    }
    let sortAccordingM = {
      0: [],
    };
    for (let p of sortedLoadLogs) {
      if (p.m === undefined || p.m === 0) {
        sortAccordingM["0"].push(p);
      } else {
        if (sortAccordingM[`${p.m}`] === undefined) {
          sortAccordingM[`${p.m}`] = [];
        }
        sortAccordingM[`${p.m}`].push(p);
      }
    }
    const averageP = averagePower(loadLogs);

    console.log(sortAccordingM, 'sortAccordingM')

    for (let i of Object.keys(sortAccordingM)) {

      // const averageP = login.userName === user[1].userName ? 2200 : averagePower(loadLogs);

      const carbonConsumedData = spendingFunction(sortAccordingM[i], deviceId, deviceDetail?.graphParameters?.carbonConsumed?.xAxisInterval, 0.83, false);

      let phaseWiseData = {
        name: `${deviceId}-Phase ${Number(i) + 1}`,
        data: Object.keys(carbonConsumedData).length <= 1
          ? []
          : carbonConsumedData.data,
        categories: Object.keys(carbonConsumedData).length <= 1
          ? []
          : carbonConsumedData.categories,
        totalSaved: carbonConsumedData.totalSaved,
      }
      globalCarbonConsumedData.push(phaseWiseData);

      console.log(phaseWiseData, 'phaseWiseData')

      // const moneySpentData = spendingFunction(sortedLoadLogs, deviceId, deviceDetail?.graphParameters?.moneySpent?.xAxisInterval, housePPU, false);
      // globalMoneySpentData.push(moneySpentData)

      // const unitsConsumedData = spendingFunction(sortedLoadLogs, deviceId, deviceDetail?.graphParameters?.unitsConsumed?.xAxisInterval, 1, false);
      // globalUnitsConsumedData.push(unitsConsumedData)

      // const onlineData = spendingFunction(sortedLoadLogs, deviceId, deviceDetail?.graphParameters?.online?.xAxisInterval, 1, true);
      // globalOnlineData.push(onlineData)

      const totalOffTime = smartOffSessions.reduce((total, session) => total + ((session.end - session.start) / 3600000), 0);

      let unitSaved = averageP * totalOffTime / 1000;
      let unitConsumed = carbonConsumedData?.unitConsumed;
      let moneySaved = unitSaved * housePPU;
      let moneySpent = unitConsumed * housePPU;
      let unitSavingPercentage = unitSaved / (unitConsumed + unitSaved);

      let carbonConsumedSaved = {}
      carbonConsumedSaved = carbonFootprintSaved(
        data.smartOffSessions,
        averageP / 1000,
        deviceDetail?.graphParameters?.carbonSaved?.xAxisInterval
      )

      globalCarbonSavedData.push({
        name: `${deviceId}-Phase ${Number(i) + 1}`,
        data: Object.keys(carbonConsumedSaved).length <= 1
          ? []
          : carbonConsumedSaved.data,
        categories: Object.keys(carbonConsumedSaved).length <= 1
          ? []
          : carbonConsumedSaved.categories,
        totalSaved: carbonConsumedSaved.totalSaved,
      });

      globalUnitSaved += isNaN(unitSaved) ? 0 : unitSaved;
      globalUnitConsumed += isNaN(unitConsumed) ? 0 : unitConsumed;
      globalMoneySaved += isNaN(moneySaved) ? 0 : moneySaved;
      globalMoneySpent += isNaN(moneySpent) ? 0 : moneySpent;
      globalUnitSavingPercentge += isNaN(unitSavingPercentage) ? 0 : unitSavingPercentage;

      phaseLoadLogs = {
        [deviceId]: {
          ...phaseLoadLogs[deviceId],
          [i]: { data: sortAccordingM[i], unit: unitConsumed },
        },
      };
    }

    // Money Saved Bar Chart Data
    let resMoneySaved = moneySavedSmartOffSessions(
      data.smartOffSessions,
      averageP,
      housePPU,
      deviceDetail?.graphParameters?.moneySaved?.xAxisInterval
    );

    globalMoneySavedData.push(resMoneySaved)
  });
  const systemValues = {
    globalMoneySaved: isNaN(globalMoneySaved) || globalMoneySaved === Infinity ? 0 : globalMoneySaved,
    globalMoneySpent: isNaN(globalMoneySpent) || globalMoneySpent === Infinity ? 0 : globalMoneySpent,
    globalUnitConsumed: isNaN(globalUnitConsumed) || globalUnitConsumed === Infinity ? 0 : globalUnitConsumed,
    globalUnitSaved: isNaN(globalUnitSaved) || globalUnitSaved === Infinity ? 0 : globalUnitSaved,
    globalUnitSavingPercentge: isNaN(globalUnitSavingPercentge) || globalUnitSavingPercentge === Infinity ? 0 : globalUnitSavingPercentge,
    time: calculateTime(data)
  };

  const graphData = {
    carbonConsumed: globalCarbonConsumedData,
    carbonSaved: globalCarbonSavedData,
    unitsConsumed: globalUnitsSavedData,
    unitsSaved: globalUnitsConsumedData,
    moneySaved: globalMoneySavedData,
    moneySpent: globalMoneySpentData,
    online: globalOnlineData
  }

  console.log(systemValues, 'systemValues')
  console.log(graphData, 'graphData')
  console.log(phaseLoadLogs, 'phaseLoadLogs')

  return { systemValues, graphData, phaseLoadLogs }
};

//----------------------------------------------------------------------------------------
export const calculateGlobalDataa = (data, housePPU) => {

  let globalMoneySpent = 0;
  let globalMoneySaved = 0;
  let globalUnitConsumed = 0;
  let globalUnitSaved = 0;
  let globalUnitSavingPercentge = 0;

  let globalCarbonConsumedData = []
  let globalCarbonSavedData = []
  let globalMoneySpentData = []
  let globalMoneySavedData = []
  let globalUnitsConsumedData = []
  let globalUnitsSavedData = []
  let globalOnlineData = []
  let phaseLoadLogs = {};

  data.forEach(data => {
    let deviceId = data.deviceId

    if (Object.keys(data).length === 0) return;

    const { loadLogs, smartOffSessions } = data;
    const sortedLoadLogs = loadLogs.sort((a, b) => parseFloat(a.ts) - parseFloat(b.ts));

    if (sortedLoadLogs.length === 0) {
      return;
    }
    let sortAccordingM = {
      0: [],
    };
    for (let p of sortedLoadLogs) {
      if (p.m === undefined || p.m === 0) {
        sortAccordingM["0"].push(p);
      } else {
        if (sortAccordingM[`${p.m}`] === undefined) {
          sortAccordingM[`${p.m}`] = [];
        }
        sortAccordingM[`${p.m}`].push(p);
      }
    }
    const averageP = averagePower(loadLogs);

    console.log(sortAccordingM, 'sortAccordingM')

    for (let i of Object.keys(sortAccordingM)) {

      // const averageP = login.userName === user[1].userName ? 2200 : averagePower(loadLogs);

      const carbonConsumedData = spendingFunction(sortAccordingM[i], deviceId, 1, 0.83, false);

      let phaseWiseData = {
        name: `${deviceId}-Phase ${Number(i) + 1}`,
        data: Object.keys(carbonConsumedData).length <= 1
          ? []
          : carbonConsumedData.data,
        categories: Object.keys(carbonConsumedData).length <= 1
          ? []
          : carbonConsumedData.categories,
        totalSaved: carbonConsumedData.totalSaved,
      }
      globalCarbonConsumedData.push(phaseWiseData);

      console.log(phaseWiseData, 'phaseWiseData')

      // const moneySpentData = spendingFunction(sortedLoadLogs, deviceId, deviceDetail?.graphParameters?.moneySpent?.xAxisInterval, housePPU, false);
      // globalMoneySpentData.push(moneySpentData)

      // const unitsConsumedData = spendingFunction(sortedLoadLogs, deviceId, deviceDetail?.graphParameters?.unitsConsumed?.xAxisInterval, 1, false);
      // globalUnitsConsumedData.push(unitsConsumedData)

      // const onlineData = spendingFunction(sortedLoadLogs, deviceId, deviceDetail?.graphParameters?.online?.xAxisInterval, 1, true);
      // globalOnlineData.push(onlineData)

      const totalOffTime = smartOffSessions.reduce((total, session) => total + ((session.end - session.start) / 3600000), 0);

      let unitSaved = averageP * totalOffTime / 1000;
      let unitConsumed = carbonConsumedData?.unitConsumed;
      let moneySaved = unitSaved * housePPU;
      let moneySpent = unitConsumed * housePPU;
      let unitSavingPercentage = unitSaved / (unitConsumed + unitSaved);

      let carbonConsumedSaved = {}
      carbonConsumedSaved = carbonFootprintSaved(
        data.smartOffSessions,
        averageP / 1000,
        1
      )

      globalCarbonSavedData.push({
        name: `${deviceId}-Phase ${Number(i) + 1}`,
        data: Object.keys(carbonConsumedSaved).length <= 1
          ? []
          : carbonConsumedSaved.data,
        categories: Object.keys(carbonConsumedSaved).length <= 1
          ? []
          : carbonConsumedSaved.categories,
        totalSaved: carbonConsumedSaved.totalSaved,
      });

      globalUnitSaved += isNaN(unitSaved) ? 0 : unitSaved;
      globalUnitConsumed += isNaN(unitConsumed) ? 0 : unitConsumed;
      globalMoneySaved += isNaN(moneySaved) ? 0 : moneySaved;
      globalMoneySpent += isNaN(moneySpent) ? 0 : moneySpent;
      globalUnitSavingPercentge += isNaN(unitSavingPercentage) ? 0 : unitSavingPercentage;

      phaseLoadLogs = {
        [deviceId]: {
          ...phaseLoadLogs[deviceId],
          [i]: { data: sortAccordingM[i], unit: unitConsumed },
        },
      };
    }

    // Money Saved Bar Chart Data
    let resMoneySaved = moneySavedSmartOffSessions(
      data.smartOffSessions,
      averageP,
      housePPU,
      1
    );

    globalMoneySavedData.push(resMoneySaved)
  });
  const systemValues = {
    globalMoneySaved: isNaN(globalMoneySaved) || globalMoneySaved === Infinity ? 0 : globalMoneySaved,
    globalMoneySpent: isNaN(globalMoneySpent) || globalMoneySpent === Infinity ? 0 : globalMoneySpent,
    globalUnitConsumed: isNaN(globalUnitConsumed) || globalUnitConsumed === Infinity ? 0 : globalUnitConsumed,
    globalUnitSaved: isNaN(globalUnitSaved) || globalUnitSaved === Infinity ? 0 : globalUnitSaved,
    globalUnitSavingPercentge: isNaN(globalUnitSavingPercentge) || globalUnitSavingPercentge === Infinity ? 0 : globalUnitSavingPercentge,
    time: calculateTime(data)
  };


  const graphData = {
    carbonConsumed: globalCarbonConsumedData,
    carbonSaved: globalCarbonSavedData,
    unitsConsumed: globalUnitsSavedData,
    unitsSaved: globalUnitsConsumedData,
    moneySaved: globalMoneySavedData,
    moneySpent: globalMoneySpentData,
    online: globalOnlineData
  }

  console.log(systemValues, 'systemValues')
  console.log(graphData, 'graphData')
  console.log(phaseLoadLogs, 'phaseLoadLogs')

  return { systemValues, graphData, phaseLoadLogs }
};

export const calculateTime = (data) => {
  console.log('====================================');
  console.log(data[0],'time');
  console.log('====================================');
  const hasSelectedDeviceData = Object.keys(data[0]).length !== 0 && data[0]?.stateLogs!==undefined &&data[0]?.stateLogs?.length !== 0;
  const time = hasSelectedDeviceData ? data[0].stateLogs[data[0].stateLogs.length - 1].ts : 0;
  return time;
};


//---------------------------------------------------------------------------------------
export const moneySavedSmartOffSessions = (smartOffSessions, averageP = 2200, cpu, slotDifference = 1) => {

  if (smartOffSessions.length !== 0) {

    let numSlots = 24 / slotDifference

    let slot = createSlots(slotDifference, numSlots)

    for (let i of smartOffSessions) {
      let obj = { startTime: i.start, endTime: i.end };
      findTimeMoneySavedOffSessions(obj, slot, slotDifference);
    }
    let barData = [];
    let barCategories = [];
    let totalOffTime = 0;
    for (let i of Object.keys(slot)) {
      barData.push((((slot[i].time / 3600000) * averageP) / 1000) * cpu);
      barCategories.push(slot[i].label);
    }

    for (let i of barData) {
      totalOffTime += Number(i);
    }

    let moneySavedData = {
      data: barData,
      categories: barCategories,
      totalSaved: totalOffTime,
    };

    return moneySavedData
  } else {
    return {};
  }
};

const findTimeMoneySavedOffSessions = (time, room, hrs) => {
  let s = new Date(time.startTime).getHours();
  let e = new Date(time.endTime).getHours();

  if (s === e) {
    room[Math.floor(s / hrs)].time += time.endTime - time.startTime;
  } else {
    for (let i = s; i <= e; i++) {
      let slotNumber = Math.floor(i / hrs);
      if (i !== e) {
        if (i === s) {
          let newStart = new Date(time.startTime);
          let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
          room[slotNumber].time += endTime - newStart;

          // if (!room[slotNumber].devices.includes(id)) {
          //     room[slotNumber].devices.push(id)
          // }
        } else {
          let newStart = new Date(time.startTime).setHours(i, 0, 0, 0);
          let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
          room[slotNumber].time += endTime - newStart;
          // if (!room[slotNumber].devices.includes(id)) {
          //     room[slotNumber].devices.push(id)
          // }
        }
      }
      if (i === e) {
        let newStart = new Date(time.startTime).setHours(e, 0, 0, 0);
        room[slotNumber].time += time.endTime - newStart;
        // if (!room[slotNumber].devices.includes(id)) {
        //     room[slotNumber].devices.push(id)
        // }
      }
    }
  }
};

//---------------------------------------------------------------------------------------
// export const Online = () => {
//   if (smartOffSessions.length !== 0) {

//     // let slot3hr = createSlots(3)
//     let slot = createSlots(1)

//     for (let i of smartOffSessions) {
//       let obj = { startTime: i.start, endTime: i.end };
//       updateRoomTimeSlots(obj, slot, 1);
//     }
//     let selectedSlot = slot
//     let barData = [];
//     let barCategories = [];
//     let totalOffTime = 0;
//     for (let i of Object.keys(selectedSlot)) {
//       barData.push((((selectedSlot[i].time / 1000 * 60 * 60) * averageP) / 1000) * cpu);
//       barCategories.push(selectedSlot[i].label);
//     }

//     for (let i of barData) {
//       totalOffTime += Number(i);
//     }

//     return {
//       data: barData,
//       categories: barCategories,
//       totalSaved: totalOffTime,
//     };
//   } else {
//     return {};
//   }
// };

//---------------------------------------------------------------------------------------
export const savedVoltageSmartOffSessions = (smartOffSessions) => { };

//---------------------------------------------------------------------------------------
// export const carbonFootprintSaved = (smartOffSessions, averageP, display = false) => {

//   //smartOffSessions will be an array of type
//   // [ { "start": 1713502918583, "end": 1713503222844 },
//   //  { "start": 1713504727977, "end": 1713505031736 },
//   //  { "start": 1713506535574, "end": 1713506840456 } ]

//   if (smartOffSessions.length !== 0) {
//     let total = 0;
//     for (let i = 0; i < smartOffSessions.length; i += 1) {
//       total += smartOffSessions[i].end - smartOffSessions[i].start;
//     }

//     let slot3hr = createSlots(3)
//     let slot = createSlots(1)

//     for (let i of smartOffSessions) {
//       let obj = { startTime: i.start, endTime: i.end };
//       updateRoomTimeSlots(obj, display ? slot3hr : slot, display ? 3 : 1);
//     }

//     let barData = [];
//     let barCategories = [];
//     let totalOffTime = 0;
//     let selectedSlot = display ? slot3hr : slot
//     for (let i of Object.keys(selectedSlot)) {
//       barData.push((((selectedSlot[i].time / (1000 * 60 * 60)) * averageP)) * 0.83);
//       barCategories.push(selectedSlot[i].label);
//     }

//     for (let i of barData) {
//       totalOffTime += Number(i);
//     }

//     return {
//       data: barData,
//       categories: barCategories,
//       totalSaved: totalOffTime,
//     };
//   } else {
//     return {};
//   }
// };

// //---------------------------------------------------------------------------------------
// export const averagePower = (loadLogs) => {

//   let totalTime = 0;
//   let weightedSum = 0;
//   for (let i = 0; i < loadLogs.length - 1; i += 1) {
//     if (loadLogs[i].p !== 0) {
//       let time = loadLogs[i + 1].ts - loadLogs[i].ts;
//       let power = loadLogs[i].p;
//       weightedSum += (power * time);
//       totalTime += time;
//     }
//   }
//   const weightedAveragePower = weightedSum / totalTime;
//   // 
//   return weightedAveragePower;
// }

export const averagePower = (loadLogs) => {
  // Return the maximum value of p from the loadLogs
  const maxPower = Math.max(...loadLogs.map(log => log.p));
  return maxPower;
}

//------------------------------------Helper Functions-----------------------------------

function timestampToMinutes(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours * 60 + minutes;
}

//---------------------------------------------------------------------------------------
const createSlots = (slotDifference, numSlots) => {


  if (!Number.isInteger(slotDifference) || slotDifference < 1 || slotDifference > 24) {
    throw new Error("Slot interval must be a whole number between 1 and 24 inclusive.");
  }

  const slots = [];

  for (let i = 0; i < numSlots; i++) {
    const hour = i * slotDifference;
    const ampm = hour < 12 ? "AM" : "PM";
    const label = (hour === 0 ? "12 " : hour > 12 ? hour - 12 : hour) + " " + ampm;

    slots.push({ time: 0, unit: 0, devices: [], label, unit: 0, unitSaved: 0 });
  }

  return slots;

  //Sample slot data for understanding
  // let slot = {
  //   0: {
  //     time: 0,
  //     unit: 0,
  //     devices: [],
  //     label: "12 AM",
  //     unitSaved: 0,
  //   },
  //   1: {
  //     time: 0,
  //     unit: 0,
  //     devices: [],
  //     label: "1 AM",
  //     unitSaved: 0,
  //   },
  //   2: {
  //     time: 0,
  //     unit: 0,
  //     devices: [],
  //     label: "2 AM",
  //     unitSaved: 0,
  //   },
}

// const createSlots = (slotDifference, numSlots) => {
//   let slots = [];
//   for (let i = 0; i < numSlots; i++) {
//     const startHour = i * slotDifference;
//     const endHour = (i + 1) * slotDifference;

//     const formatTime = (hour) => {
//       const period = hour < 12 || hour === 24 ? 'AM' : 'PM';
//       const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
//       return `${formattedHour}:00 ${period}`;
//     };

//     slots.push({ 
//       unit: 0, 
//       unitSaved: 0, 
//       time: 0, 
//       label: `${formatTime(startHour)} - ${formatTime(endHour)}`
//     });
//   }
//   return slots;
// };

//---------------------------------------------------------------------------------------
function findTime(time, room, p) {

  const timeDiff = time.endTime - time.startTime;
  const unitConsumed = (p * (timeDiff / (1000 * 60 * 60))) / 1000;
  const unitSaved = ((100 * (timeDiff / (1000 * 60 * 60))) / 1000) - unitConsumed;
  const slotNumber = Math.floor(new Date(time.startTime).getHours());

  if (room[slotNumber].time) {
    room[slotNumber].time += timeDiff;
    room[slotNumber].unit += unitConsumed;
    room[slotNumber].unitSaved += unitSaved;
  }
}

// const calculateUnitConsumedAndSaved = (timeDiff, p) => {
//   const unitConsumed = (p * (timeDiff / (1000 * 60 * 60))) / 1000;
//   const unitSaved = ((100 * (timeDiff / (1000 * 60 * 60))) / 1000) - unitConsumed;
//   return {
//     unitConsumed: isNaN(unitConsumed) ? 0 : unitConsumed,
//     unitSaved: isNaN(unitSaved) ? 0 : unitSaved
//   };
// };

// const findTime = (time, room, p) => {
//   const startTime = new Date(time.startTime);
//   const endTime = new Date(time.endTime);
//   const startHour = startTime.getHours();
//   const endHour = endTime.getHours();

//   for (let i = startHour; i <= endHour; i++) {
//     const slotNumber = Math.floor(i);
//     const newStart = new Date(startTime).setHours(i, 0, 0, 0);
//     const timeDiff = (i === startHour ? Math.min(new Date(newStart).setHours(i + 1, 0, 0, 0), endTime) : Math.min(new Date(newStart).setHours(i + 1, 0, 0, 0), endTime)) - newStart;

//     room[slotNumber].time += timeDiff;

//     const { unitConsumed, unitSaved } = calculateUnitConsumedAndSaved(timeDiff, p);

//     room[slotNumber].unitSaved += unitSaved;
//     room[slotNumber].unit += unitConsumed;
//   }
// };


// 
const updateRoomTimeSlots = (time, room, hrs) => {
  const startHour = new Date(time.startTime).getHours();
  const endHour = new Date(time.endTime).getHours();

  if (startHour === endHour) {
    const slotNumber = Math.floor(startHour / hrs);
    room[slotNumber].time += time.endTime - time.startTime;
  } else {
    for (let i = startHour; i < endHour; i++) {
      const slotNumber = Math.floor(i / hrs);
      const slotStartTime = new Date(time.startTime);
      slotStartTime.setHours(i, 0, 0, 0);
      const slotEndTime = new Date(time.startTime);
      slotEndTime.setHours(i + 1, 0, 0, 0);
      room[slotNumber].time += slotEndTime - slotStartTime;
    }
    const slotNumber = Math.floor(endHour / hrs);
    const lastSlotStartTime = new Date(time.startTime);
    lastSlotStartTime.setHours(endHour, 0, 0, 0);
    room[slotNumber].time += time.endTime - lastSlotStartTime;
  }
};

//----------------------------------------Carbon Consumed------------------------------------------
export const spendingFunction = (log, id, slotDifference = 1, multiplicationFactor = 1, isOnlineGraph) => {

  if (log.length !== 0) {

    let numSlots = 24 / slotDifference // Because there are 24 hrs in a day

    let slot = createSlots(slotDifference, numSlots)

    for (let i = 0; i <= log.length; i++) {
      if (log[i + 1] !== undefined) {
  
        findTimeOfCarbonConsumed(
          { startTime: log[i].ts, endTime: log[i + 1].ts },
          slot,
          log[i].p,
          slotDifference
        );
     
      }
    }
    let total = 0;
    let totalCarbonSaved = 0;
    let totalOntime = 0
    for (let i = 0; i < numSlots; i++) {
      if (slot[i].unit !== 0) {
        if (slot[i].unit === undefined || isNaN(slot[i].unit)) {
          slot[i].unit = 0;
        }
        total += slot[i].unit;
        totalCarbonSaved += slot[i].unitSaved
        totalOntime += slot[i].time
        slot[i].unit = slot[i].unit * multiplicationFactor;
        slot[i].unitSaved = slot[i].unitSaved * multiplicationFactor;
      }
    }

    let barData = [];
    let barCategories = [];
    let carbonSavedData = [];
    for (let i of Object.keys(slot)) {
      barData.push(isOnlineGraph ? slot[i].time / (1000 * 60 * 60) : slot[i].unit);
      carbonSavedData.push(slot[i].unitSaved);
      barCategories.push(slot[i].label);
    }

    let requiredData = {
      data: barData,
      categories: barCategories,
      totalSaved: total * multiplicationFactor,
      unitConsumed: total,
      totalOntime: totalOntime / (1000 * 60 * 60),
      carbonSavedData: carbonSavedData,
      totalCarbonSaved: totalCarbonSaved * multiplicationFactor,
    };

    return requiredData
  }
};

export const spendingFunctionKvaH = (log, slotDifference = 1, multiplicationFactor = 1, isOnlineGraph) => {

  if (log.length !== 0) {

    let numSlots = 24 / slotDifference // Because there are 24 hrs in a day

    let slot = createSlots(slotDifference, numSlots)

    for (let i = 0; i <= log.length; i++) {
      if (log[i + 1] !== undefined) {
  
        findTimeOfCarbonConsumed(
          { startTime: log[i].ts, endTime: log[i + 1].ts },
          slot,
          log[i].c*log[i].v,
          slotDifference
        );
     
      }
    }
    let total = 0;
    let totalCarbonSaved = 0;
    let totalOntime = 0
    for (let i = 0; i < numSlots; i++) {
      if (slot[i].unit !== 0) {
        if (slot[i].unit === undefined || isNaN(slot[i].unit)) {
          slot[i].unit = 0;
        }
        total += slot[i].unit;
        totalCarbonSaved += slot[i].unitSaved
        totalOntime += slot[i].time
        slot[i].unit = slot[i].unit * multiplicationFactor;
        slot[i].unitSaved = slot[i].unitSaved * multiplicationFactor;
      }
    }

    let barData = [];
    let barCategories = [];
    let carbonSavedData = [];
    for (let i of Object.keys(slot)) {
      barData.push(isOnlineGraph ? slot[i].time / (1000 * 60 * 60) : slot[i].unit);
      carbonSavedData.push(slot[i].unitSaved);
      barCategories.push(slot[i].label);
    }

    let requiredData = {
      data: barData,
      categories: barCategories,
      totalSaved: total * multiplicationFactor,
      unitConsumed: total,
      totalOntime: totalOntime / (1000 * 60 * 60),
      carbonSavedData: carbonSavedData,
      totalCarbonSaved: totalCarbonSaved * multiplicationFactor,
    };

    return requiredData
  }
};

const findTimeOfCarbonConsumed = (time, room, p, slotDifference) => {
  const startTime = new Date(time.startTime);
  const endTime = new Date(time.endTime);


  if (startTime.getHours() === endTime.getHours()) {
    processTimeSlot(startTime, endTime, room, p, slotDifference);
  } else {
    for (let i = startTime.getHours(); i <= endTime.getHours(); i++) {
      if (i === startTime.getHours()) {
        const nextHourStartTime = new Date(startTime).setHours(i + 1, 0, 0, 0);
        processTimeSlot(startTime, nextHourStartTime, room, p, slotDifference);
      } else if (i === endTime.getHours()) {
        const slotEndTime = new Date(startTime).setHours(i, 0, 0, 0);
        processTimeSlot(slotEndTime, endTime, room, p, slotDifference);
      } else {
        const slotStartTime = new Date(startTime).setHours(i, 0, 0, 0);
        const nextHourStartTime = new Date(startTime).setHours(i + 1, 0, 0, 0);
        processTimeSlot(slotStartTime, nextHourStartTime, room, p, slotDifference);
      }
    }
  }
};

const processTimeSlot = (startTime, endTime, room, p, slotDifference) => {
  const slotStartTime = new Date(startTime);
  const slotEndTime = new Date(endTime);

  const slotNumber = Math.floor(slotStartTime.getHours() / slotDifference);
  const timeDiff = slotEndTime - slotStartTime;

  room[slotNumber].time += timeDiff;

  const { unitConsumed, unitSaved } = calculateUnits(timeDiff, p);

  room[slotNumber].unitSaved += unitSaved;
  room[slotNumber].unit += unitConsumed;
};

export const calculateUnits = (timeDiff, p) => {
  const unitConsumed = (p * (timeDiff / (1000 * 60 * 60))) / 1000;
  const unitSaved = ((100 * (timeDiff / (1000 * 60 * 60))) / 1000) - unitConsumed;
  return { unitConsumed: isNaN(unitConsumed) ? 0 : unitConsumed, unitSaved: isNaN(unitSaved) ? 0 : unitSaved };
};


//--------------------------------------------------------------------------------------------------------------------------

//----------------------------------------------Carbon Saved----------------------------------------------------------------
export const carbonFootprintSaved = (smartOffSessions, averageP, slotDifference = 1) => {

  if (smartOffSessions.length !== 0) {
    let total = 0;
    for (let i = 0; i < smartOffSessions.length; i += 1) {
      total += smartOffSessions[i].end - smartOffSessions[i].start;
    }

    let numSlots = 24 / slotDifference // Because there are 24 hrs in a day
    let slots = createSlots(slotDifference, numSlots)// Sample slot data in the function

    for (let i of smartOffSessions) {
      let obj = { startTime: i.start, endTime: i.end };
      findTimeCarbonSaved(obj, slots, slotDifference);
    }
    let barData = [];
    let barCategories = [];
    let totalOffTime = 0;
    for (let i of Object.keys(slots)) {
      barData.push((((slots[i].time / (1000 * 60 * 60)) * averageP)) * 0.83); // Main formula to calculate carbon footprint saved
      barCategories.push(slots[i].label);
    }

    for (let i of barData) {
      totalOffTime += Number(i);
    }

    return {
      data: barData,
      categories: barCategories,
      totalSaved: totalOffTime,
    };
  } else {
    return {};
  }
};

const findTimeCarbonSaved = (time, room, hrs) => {
  let s = new Date(time.startTime).getHours();
  let e = new Date(time.endTime).getHours();

  if (s === e) {
    room[Math.floor(s / hrs)].time += time.endTime - time.startTime;
  } else {
    for (let i = s; i <= e; i++) {
      let slotNumber = Math.floor(i / hrs);
      if (i !== e) {
        if (i === s) {
          let newStart = new Date(time.startTime);
          let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
          room[slotNumber].time += endTime - newStart;
        } else {
          let newStart = new Date(time.startTime).setHours(i, 0, 0, 0);
          let endTime = new Date(time.startTime).setHours(i + 1, 0, 0, 0);
          room[slotNumber].time += endTime - newStart;
        }
      }
      if (i === e) {
        let newStart = new Date(time.startTime).setHours(e, 0, 0, 0);
        room[slotNumber].time += time.endTime - newStart;
      }
    }
  }
};


export const decimalToBinary=(decimal)=> {
  let binary = "";
  while (decimal > 0) {
      binary = (decimal % 2) + binary; // Get the remainder and prepend it to the binary string
      decimal = Math.floor(decimal / 2); // Update the decimal value by dividing by 2
  }
  return binary || "00000"; // Return "0" if input is 0
}
//--------------------------------------------------------------------------------------------------------------------------

